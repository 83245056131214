import { changeOpacity } from "./utils";

function openImageModal(event) {
  event.preventDefault();
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style["overflow"] = "hidden";
  document.body.style["padding-right"] = `${scrollbarWidth}px`;

  const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
  if (headerNoticeWrap) {
    headerNoticeWrap.style["padding-right"] = `${scrollbarWidth}px`;
  }

  const source = event.target;
  const modal = document.getElementById("ImageModal");

  const heading = document.getElementById("ImageModal__heading");
  const alt = source.alt?.length > 0 ? source.alt : "";
  heading.innerText = alt;

  const link = source.closest("a");
  const src = link.href;

  const img = document.getElementById("ImageModal__img");
  img.src = src;
  img.alt = alt;

  img.onload = function () {
    document.getElementById("ImageModal__spinner").classList.add("hidden");

    const img_tag = document.getElementById("ImageModal__img");
    img_tag.classList.remove("hidden");
    img_tag.width = img.width;
    img_tag.height = img.height;
    changeOpacity("ImageModal__img", 100);
  };

  modal.classList.remove("hidden");
  changeOpacity("ImageModal__bg", 100);

  return false;
}

window.openImageModal = openImageModal;

function closeImageModal(event) {
  event.stopPropagation();

  const target = event.target;
  const closeIcon = target.closest("#ImageModal__close");

  if (target.id == "ImageModal__bg" || closeIcon) {
    document.body.style["overflow"] = "visible";
    document.body.style["padding-right"] = "0px";
    const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
    if (headerNoticeWrap) {
      headerNoticeWrap.style["padding-right"] = "0px";
    }

    const modal_bg = document.getElementById("ImageModal__bg");
    modal_bg.style["opacity"] = 0;

    setTimeout(function () {
      document.getElementById("ImageModal").classList.add("hidden");
      document.getElementById("ImageModal__spinner").classList.remove("hidden");

      const img_tag = document.getElementById("ImageModal__img");
      img_tag.classList.add("hidden");
      img_tag.style["opacity"] = 0;
    }, 200);
  }

  return false;
}

window.closeImageModal = closeImageModal;

function initImageModal() {
  const modal = document.getElementById("ImageModal__bg");
  const modal_close = document.getElementById("ImageModal__close");

  if (modal) {
    modal.addEventListener("click", closeImageModal);
    modal_close.addEventListener("click", closeImageModal);
  }
}

initImageModal();
