import { debounce, viewport, getNext } from "./utils";

function initSlider(id) {
  const slider = document.getElementById(`${id}__row`);
  const slidesCount = slider.childElementCount;

  for (let i = 1; i <= slidesCount; i++) {
    createObserver(id, i);
  }

  const l = document.getElementById(`${id}__btnL`);
  const r = document.getElementById(`${id}__btnR`);

  l.addEventListener("click", slide.bind(this, id, "left"));
  r.addEventListener("click", slide.bind(this, id, "right"));
}

window.initSlider = initSlider;

function createObserver(id, i) {
  const options = {
    root: document.getElementById(`${id}__row`),
    threshold: 0.1,
  };

  const observer = new IntersectionObserver(
    itemEntered.bind(this, id, i),
    options
  );

  const target = document.getElementById(`${id}__item${i}`);
  observer.observe(target);
}

function itemEntered(id, i, entries) {
  const [entry] = entries;

  if (entry.intersectionRect.width > 0 && entry.isIntersecting) {
    const width = entry.boundingClientRect.width * (i - 1);
    debouncedScrollTo(id, width);
  }
}

const debouncedScrollTo = debounce((id, left) => {
  scrollTo(id, left);
}, 500);

function scrollTo(id, left) {
  const slider = document.getElementById(`${id}__row`);
  slider.scrollTo({ left, behavior: "smooth" });
}

function slide(id, direction) {
  const slider = document.getElementById(`${id}__row`);
  const slidesCount = slider.childElementCount;
  const left = slider.scrollLeft;
  const width = slider.scrollWidth;

  const stepWidth = width / slidesCount;
  const actualStep =
    direction == "right"
      ? Math.floor(left / stepWidth) + 1
      : Math.ceil(left / stepWidth) + 1;

  const nextDirection = direction === "right" ? "up" : "down";
  const nextStep = getNext(nextDirection, actualStep, 1, slidesCount);

  const nextLeft = (nextStep - 1) * stepWidth;
  scrollTo(id, nextLeft);
}
