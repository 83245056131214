import { getCookie, setCookie } from "./utils";

function initHeader() {
  let peg =
    document.getElementById("arrow_down") ||
    document.getElementById("post_breadcrumb");

  const headerObserver = new IntersectionObserver(stickyNav, {
    root: null,
    threshold: 0,
  });

  headerObserver.observe(peg);
}

const stickyNav = function (entries) {
  const isDarkTheme =
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches);

  const [entry] = entries;

  if (
    !isDarkTheme &&
    entry.boundingClientRect.height + entry.boundingClientRect.top < 0
  ) {
    setHeaderTheme("light");
  } else {
    setHeaderTheme("dark");
  }
};

function setHeaderTheme(theme) {
  const header = document.getElementById("header");
  const logo = document.getElementById("logo");
  const logo_white = document.getElementById("logo_white");

  if (theme == "dark") {
    header.classList.remove("nav15-bg-sticky");
    header.classList.add("nav15-bg-standard");

    logo_white.classList.remove("hidden");
    logo.classList.add("hidden");
  } else {
    header.classList.remove("nav15-bg-standard");
    header.classList.add("nav15-bg-sticky");

    logo_white.classList.add("hidden");
    logo.classList.remove("hidden");
  }
}

window.setHeaderTheme = setHeaderTheme;

function showSubmenu(id) {
  if (!window.menuListener) {
    const submenu = document.getElementById(`s_${id}`);

    if (submenu.classList.contains("hidden")) {
      submenu.classList.remove("hidden");
      setTimeout(() => {
        window.menuListener = hideSubmenu.bind(this, id);
        document.addEventListener("click", window.menuListener);
      }, 100);
    }
  }
}

window.showSubmenu = showSubmenu;

function hideSubmenu(id, ev) {
  const entry = ev.target.closest(`#e_${id}`);
  const parent = ev.target.closest(`#p_${id}`);

  if (parent === null || (parent && entry)) {
    document.getElementById(`s_${id}`).classList.add("hidden");
    document.removeEventListener("click", window.menuListener);
    window.menuListener = null;
  }
}

function closeHeaderNotice() {
  setCookie("HeaderNoticeClosed_newlogo", "true", 1000);

  const bar = document.getElementById("HeaderNotice");
  bar.style["margin-top"] = `-${bar.clientHeight}px`;

  const wrap = document.getElementById("HeaderNoticeWrap");
  wrap.style["padding-bottom"] = "0";

  const header = document.getElementById("HeaderTag");
  header.style["padding-bottom"] = "0";
}

function initHeaderNotice() {
  const close = document.getElementById("HeaderNotice__close");

  if (close) {
    close.addEventListener("click", closeHeaderNotice);
  }
}

function onOpenLoginModal(event) {
  const instance = getCookie("instance");

  if (instance === undefined) {
    openModal("LoginModal", event);
    const LoginModal = document.getElementById("LoginModal__bg");
    const LoginModal_close = document.getElementById("LoginModal__close");

    LoginModal.addEventListener("click", closeModal.bind(this, "LoginModal"));
    LoginModal_close.addEventListener(
      "click",
      closeModal.bind(this, "LoginModal")
    );

    const LoginModal__submit = document.getElementById("LoginModal__submit");
    LoginModal__submit.addEventListener("click", () => {
      const subdomain = document
        .getElementById("LoginModal__subdomain")
        .value.trim();
      if (subdomain != "") {
        window.location.href = "https://" + subdomain + ".projektove.cz";
      }
    });
  } else {
    window.location.href = "https://" + instance + ".projektove.cz";
  }
}

function initLoginModal() {
  const LoginModal = document.getElementById("LoginModal__bg");
  const LoginBtn = document.getElementById("LoginBtn");

  if (LoginModal && LoginBtn) {
    LoginBtn.addEventListener("click", onOpenLoginModal);
  }
}

initHeader();
initHeaderNotice();
initLoginModal();
