function toggleAccordeon(id) {
  const icon = document.getElementById(`acc_icon_${id}`);
  const content = document.getElementById(`acc_content_${id}`);
  console.log('aaa')

  if (icon && content) {
    if (content.classList.contains("hidden")) {
      icon.classList.add("-rotate-180");
      content.classList.remove("hidden");
    } else {
      content.classList.add("hidden");
      icon.classList.remove("-rotate-180");
    }
  }
}

window.toggleAccordeon = toggleAccordeon;
